@import "../../variables.modules";

.pink {
  color: $pink;
}

.green {
  color: $green;
}

.skills {
  columns: 1;

  @media only screen and (min-width: 940px) {
    columns: 2;
  }

  li {
    margin: 0;
    line-height: 1.75;
  }
}

input[type='file'],
input[type='password'] {
  background-color: black;
  border: solid;
  border-radius: 10px;
  padding: 10px;
  font-size: 22px;
}

input[type='file'],
input[type='password']:hover {
  background-color: black;
  box-shadow: white 0px 0px 10px 8px;
  transition: 0.5s;
}

input[type='file'],
input[type='password']:active {
  background-color: black;
  box-shadow: $green 0px 0px 10px 8px;
  transition: 0.2s;
}

button {
  border: solid;
  border-radius: 10px;
  padding: 10px;
  font-size: 22px;
}

button:hover {
  box-shadow: white 0px 0px 10px 8px;
  transition: 0.5s;
}

button:active {
  box-shadow: $green 0px 0px 10px 8px;
  transition: 0.2s;
}